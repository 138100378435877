// React
import React from 'react';

// Tools / Library
import {escStr} from '../Utils'
import {isEmpty} from 'lodash'
import {ImClock} from 'react-icons/im';
import {MdKeyboardArrowLeft} from 'react-icons/md';

// Components
import UnknownScreen from './UnknownScreen';
import Navbar from './Navbar';
import Box from './Box';


class CategoryScreen extends React.Component {

	///////////////////////////////////////
	// Variables
	selectedCategoryIndex = -1;
	saveCategoryId = -1;


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			step: '',
			notice: '',
		};

		// Bind this to functions
		this.validatePassword = this.validatePassword.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		window.scrollTo(0, 0);
		this.updateCategoryDisplay();
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		if (typeof(this.props.historyHandler) !== "undefined") {
			this.props.historyHandler(this.props.historyProps);
		}
	}


	///////////////////////////////////////
	// Component Update
	componentDidUpdate() {
		if (this.saveCategoryId !== this.props.categoryId) {
			this.updateCategoryDisplay();
		}
	}


	///////////////////////////////////////
	// Render
	render() {
		let pageClass = "page-dispatch";
		let pageContent = "";

		///////////////////////////////////////
		// Display Content
		if (this.state.step === 'display' && this.selectedCategoryIndex !== -1) {
			let categoryContent = [];
			for (let i=0; i<this.props.appData.contents.length; i++) {
				if (this.props.appData.contents[i].parent === parseInt(this.props.categoryId, 10)) { categoryContent.push(this.props.appData.contents[i]); }
			}

			// List Content
			let listContent = "";
			if (!isEmpty(categoryContent)) {
				listContent =
					<ul className="ww-boxList">
						{categoryContent.map( item => {
							// Link
							let boxLink = (item.type === 'post' || item.type === 'post_sticky') ? 'post/' + item.id : 'category/' + item.id;

							// Special type
							let context = 'category_box';
							if (item.special === 'partenaires') { context = 'partenaires_list'; }
							if (item.special === 'visites') { context = 'visites_list'; }
							if (item.special === 'escales') { context = 'escales_list'; }
							if (item.special === 'agenda') { context = 'agenda'; }
							if (item.special === 'signalement') { context = 'signalement'; return ''; }

							// Duree
							let articleDuree = ((item.type !== 'post' && item.type !== 'post_sticky') || typeof(item.time) === 'undefined') ? '' :
								<ul className="ww-box__overlayPictos ww-box__overlayPictos--noborder">
									<li><ImClock size="26px" /><span>{item.time}min</span></li>
								</ul>;

							// Overlay
							let boxContent =
								<div className="ww-box__overlay">
									<div className="ww-box__overlayContent">
										<p className="ww-box__title">{escStr(item.title)}</p>
									</div>
									{articleDuree}
								</div>;

							// Image
							let imageClass = '';
							let imageUrl = item.image;
							let imageWidth = item.image_width;
							let imageHeight = item.image_height;
							if (imageUrl.length === 0) {
								imageClass = 'ww-box__nooverlay';
								imageUrl = this.props.appData.default_image.url;
								imageWidth = this.props.appData.default_image.width;
								imageHeight = this.props.appData.default_image.height;
							}

							return (
								<li key={item.id}>
									<Box
										boxClass={imageClass}
										boxImage={imageUrl}
										boxImageWidth={imageWidth}
										boxImageHeight={imageHeight}
										boxContent={boxContent}
										handleClickEvent={this.props.handleClickEvent}
										handleClickContext={context}
										handleClickValue={boxLink}
										handleClickHistoryProps={this.props.historyProps}
									/>
								</li>
							);
						})}
					</ul>;
			}
			else {
				listContent = <h3 className="ww-boxList--empty">{escStr(this.props.appData.tStrings.aucun_resultat)}</h3>;
			}

			pageContent =
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					<h2 className="ww-boxList__title">{escStr(this.props.appData.contents[this.selectedCategoryIndex].title)}</h2>
					{listContent}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>;
		}


		///////////////////////////////////////
		// Protected
		else if (this.state.step === 'forbidden') {

			let protectedContent =
				<div className="ww-passwordForm ww-form">
					<p>{escStr(this.props.appData.tStrings.contenu_protege)}</p>
					<input id="password" type="password" />
					<button className="ww-button--primary" type="button" onClick={this.validatePassword}>{escStr(this.props.appData.tStrings.valider)}</button>
					<p>{this.state.notice}</p>
				</div>;


			pageContent =
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					<h2 className="ww-boxList__title">{escStr(this.props.appData.contents[this.selectedCategoryIndex].title)}</h2>
					{protectedContent}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>;
		}


		///////////////////////////////////////
		// Unknown
		else if (this.state.step === 'unknown') {
			pageClass = "page-dispatch page-pageUnknown";
			pageContent =
				<UnknownScreen
					appOptions={this.props.appOptions}
					appData={this.props.appData}
				/>;
		}


		return(
			<div className={pageClass}>
				{pageContent}
			</div>
		);
	}


	///////////////////////////////////////
	// Validate Password
	validatePassword() {

		let password = document.getElementById('password');
		if (password !== null) {
			if (this.$md5(password.value) === this.props.appData.espacePartenairePassword) {
				if (typeof(Storage) !== 'undefined') {
					localStorage.setItem('espacePartenaireMD5', this.props.appData.espacePartenairePassword);
					this.setState({step: 'display'});
					return;
				}
			}
		}

		this.setState({notice: escStr(this.props.appData.tStrings.contenu_mot_passe_invalide)});

	}


	///////////////////////////////////////
	// Update Category Display
	updateCategoryDisplay() {

		// Init Category Index
		this.saveCategoryId = this.props.categoryId;
		for (let i=0; i<this.props.appData.contents.length; i++) {
			if (this.props.appData.contents[i].id === parseInt(this.props.categoryId, 10)) { this.selectedCategoryIndex = i; }
		}
		if (this.selectedCategoryIndex === -1) { this.setState({step: 'unknown'}); }
		else if (this.props.appData.contents[this.selectedCategoryIndex].protected === 1) {
			if (typeof(Storage) !== 'undefined') {
				const lsEspacePartenaireMD5 = localStorage.getItem('espacePartenaireMD5');
				if (typeof(lsEspacePartenaireMD5) !== 'undefined'
					&& lsEspacePartenaireMD5 !== null
					&& lsEspacePartenaireMD5 ===  this.props.appData.espacePartenairePassword
					) {
					this.setState({step: 'display'});
				}
				else { this.setState({step: 'forbidden'}); }
			}
			else { this.setState({step: 'forbidden'}); }
		}
		else { this.setState({step: 'display'}); }

	}

}

export default CategoryScreen;
