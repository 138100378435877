// React
import React from 'react';
import {MapContainer, MapConsumer, TileLayer, Marker} from 'react-leaflet';
import L from 'leaflet';

// Tools / Library
import {escStr, fetchWithTimeout} from '../Utils'
import {MdKeyboardArrowLeft, MdAttachFile} from 'react-icons/md';
import {AiFillCamera} from 'react-icons/ai';

// Components
import Navbar from './Navbar';
import ListSelect from './ListSelect';

import mapImgUser from '../Images/map-user.svg';
const nl2br = require('react-nl2br');


class SignalementsScreen extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	userLocation = null;
	map = null;
	mapOptions = {
		mapOpacity: 0.6,
		mapMarkerSize: [28, 40],
		mapMarkerAnchor: [14, 40],
	};
	photoStream = null;
	photoBlob = null;
	photoUrl = '';


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			geolocation: false,
			photoStatus: 0,
			photoSending: false,
			photoError: '',
			formType: -1,
			formGravite: -1,
			formAdresse: '',
			formDescription: '',
			formEmail: '',
			formSent: false,
		};

		// Bind this to functions
		this.handleSelect = this.handleSelect.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.sendSignalementForm = this.sendSignalementForm.bind(this);
		this.takeSnapshot = this.takeSnapshot.bind(this);
		this.saveSnapshot = this.saveSnapshot.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);

		// Init Geolocation
		if (this.state.geolocation === false) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					location => {
						if (this.mounted === true) {
							//console.log('Geolocation', location);
							this.userLocation = location;
							this.setState({ geolocation : true });
						}
					},
					positionError => {
						if (this.mounted === true) {
							this.setState({ geolocation : true });
						}
					},
					{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
				);
			}
			else {
				this.setState({ geolocation : true });
			}
		}
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Component Did Update
	componentDidUpdate() {
		// Update Map Position
		if (this.map !== null && this.userLocation !== null) {
			this.map.setView([this.userLocation.coords.latitude, this.userLocation.coords.longitude], 17);
		}
		// Update Photo
		if (this.state.photoStatus === 1 && this.state.photoError.length === 0) { this.getPhotoStream(); }
		else {
			this.stopPhotoStream();
			if (this.state.photoStatus === 2 && this.photoBlob !== null) {
				const photo = document.getElementById('photoPopup__image');
				if (photo !== null) {
					photo.src = URL.createObjectURL(this.photoBlob);
				}
			}
		}
	}


	///////////////////////////////////////
	// Render
	render() {
		let pageClass = "page-signalement";
		let pageContent = "";

		///////////////////////////////////////
		// Introduction
		let introduction = '';
		if (this.state.formSent !== true) {
			introduction = <p className="ww-signalement__introduction">{nl2br(escStr(this.props.appData.tStrings.signalement_introduction))}</p>;
		}

		///////////////////////////////////////
		// Beta
		let beta = '';
		if (this.state.formSent !== true) {
			if (this.props.splashData.code === 'fr') {
				beta = <p className="ww-signalement__beta">Cette fonctionnalité est une version bêta. Si vous constatez une anomalie, n'hésitez pas à nous en faire part sur communication@haropaport.com</p>;
				if (this.state.geolocation === true) {
					beta = <p className="ww-signalement__beta">Cette fonctionnalité est une version bêta. Si vous constatez une anomalie, n'hésitez pas à nous en faire part sur communication@haropaport.com<br /><br />Merci de vérifier que la géolocalisation correspond bien au lieu de l’incident. Si ce n’est pas le cas, merci de nous communiquer un maximum d’information sur le lieu de l’incident dans l’onglet descriptif.</p>;
				}
			}
			else {
				beta = <p className="ww-signalement__beta">This functionnality is in beta test. If you find a bug, please report it to us at communication@haropaport.com</p>;
				if (this.state.geolocation === true) {
					beta = <p className="ww-signalement__beta">This functionnality is in beta test. If you find a bug, please report it to us at communication@haropaport.com<br /><br />Please check that the geolocation macthes the actual location of the incident. If it does not, please ass as many details as possible in the description box.</p>;
				}
			}
		}

		///////////////////////////////////////
		// User position
		const mapUser = new L.Icon({
			iconUrl: mapImgUser,
			iconSize: [20, 20],
			iconAnchor: [10, 10]
		});
		let userMarker = "";
		let position = [48.856614, 2.352221];
		if (this.state.geolocation === true && this.userLocation !== null) {
			position = [this.userLocation.coords.latitude, this.userLocation.coords.longitude];
			userMarker = <Marker position={position} icon={mapUser}></Marker>;
		}

		///////////////////////////////////////
		// Map
		let signalementMap = '';
		if (this.state.formSent === false) {
			if (this.state.geolocation === true && this.userLocation !== null) {
				signalementMap =
					<div className="ww-signalement__map">
						<MapContainer center={position} zoom={17} zoomSnap={0.1}>
							<MapConsumer>{(map) => { this.map = map; return null; }}</MapConsumer>
							<TileLayer url={this.props.appData.mapProvider} opacity={this.mapOptions.mapOpacity} />
							{userMarker}
						</MapContainer>
					</div>;
			}
			else if (this.state.geolocation === true && this.userLocation === null) {
				signalementMap =
					<div className="ww-banner">
						{nl2br(escStr(this.props.appData.tStrings.signalement_geoloc))}
					</div>;
			}
		}

		///////////////////////////////////////
		// Type
		let typeSelect = '';
		const typeValues = [
			{ id : 'proprete', name : escStr(this.props.appData.tStrings.signalement_proprete) },
			{ id : 'circulation', name : escStr(this.props.appData.tStrings.signalement_circulation) },
			{ id : 'pollution', name : escStr(this.props.appData.tStrings.signalement_pollution) },
			{ id : 'autres', name : escStr(this.props.appData.tStrings.signalement_autres) },
		];
		if (this.state.formSent === false) {
			typeSelect =
				<ListSelect
					handleSelect={this.handleSelect}
					listId="select-type"
					listTitle={escStr(this.props.appData.tStrings.signalement_type) + '(*)'}
					listItems={typeValues}
					listEmpty="-"
					listSelectedId={this.state.formType}
				/>;
		}

		///////////////////////////////////////
		// Gravité
		let graviteSelect = '';
		const graviteValues = [
			{ id : 'dangereux', name : escStr(this.props.appData.tStrings.signalement_dangereux) },
			{ id : 'grave', name : escStr(this.props.appData.tStrings.signalement_grave) },
			{ id : 'genant', name : escStr(this.props.appData.tStrings.signalement_genant) },
		];
		if (this.state.formSent === false) {
			graviteSelect =
				<ListSelect
					handleSelect={this.handleSelect}
					listId="select-gravite"
					listTitle={escStr(this.props.appData.tStrings.signalement_gravite) + '(*)'}
					listItems={graviteValues}
					listEmpty="-"
					listSelectedId={this.state.formGravite}
				/>;
		}

		///////////////////////////////////////
		// Formulaire
		let signalementForm = '';
		if (this.state.formSent === false) {
			let labelAdresse = '';
			let champAdresse = '';
			if (this.userLocation === null) {
				labelAdresse = <label>{escStr(this.props.appData.tStrings.adresse) + '(*)'}</label>;
				champAdresse = <textarea name="adresse" onChange={ (e) => this.handleChange('adresse', e.target.value)}></textarea>;
			}

			// Photo
			let photoContent = '';
			if (this.state.photoStatus === 0) {
				photoContent = <button id="ww-button--photo" className="ww-button--secondary" type="button" onClick={() => { this.setState({photoStatus: 1})}}><AiFillCamera size="18" />{escStr(this.props.appData.tStrings.signalement_joindre_photo)}</button>;
			}
			if (this.state.photoStatus === 3) {
				photoContent = <p id="ww-photo--attached"><strong><MdAttachFile size="18" />{escStr(this.props.appData.tStrings.signalement_photo_jointe)}</strong></p>;
			}

			signalementForm =
				<form className="ww-form">
					{labelAdresse}
					{champAdresse}
					<label>{escStr(this.props.appData.tStrings.description) + '(*)'}</label>
					<textarea name="description" onChange={ (e) => this.handleChange('description', e.target.value)}></textarea>
					<label>{escStr(this.props.appData.tStrings.votre_email)}</label>
					<input type="text" name="email" onChange={ (e) => this.handleChange('email', e.target.value)} />
					{photoContent}
					<button className="ww-button--primary" type="button" onClick={this.sendSignalementForm}>{escStr(this.props.appData.tStrings.envoyer)}</button>
					<p id="ww-form__message"></p>
				</form>;
		}

		///////////////////////////////////////
		// Remerciements
		let remerciements = '';
		if (this.state.formSent === true) {
			if (this.state.formGravite === 'dangereux') {
				remerciements =
					<div>
						<p className="ww-signalement__remerciementsTitle">{nl2br(escStr(this.props.appData.tStrings.signalement_confirmation_remerciements))}</p>
						<p className="ww-signalement__remerciements">{nl2br(escStr(this.props.appData.tStrings.signalement_confirmation_dangereux))}</p>
					</div>;
			}
			if (this.state.formGravite === 'grave') {
				remerciements =
					<div>
						<p className="ww-signalement__remerciementsTitle">{nl2br(escStr(this.props.appData.tStrings.signalement_confirmation_remerciements))}</p>
						<p className="ww-signalement__remerciements">{nl2br(escStr(this.props.appData.tStrings.signalement_confirmation_grave))}</p>
					</div>;
			}
			if (this.state.formGravite === 'genant') {
				remerciements =
					<div>
						<p className="ww-signalement__remerciementsTitle">{nl2br(escStr(this.props.appData.tStrings.signalement_confirmation_remerciements))}</p>
						<p className="ww-signalement__remerciements">{nl2br(escStr(this.props.appData.tStrings.signalement_confirmation_genant))}</p>
					</div>;
			}
		}

		///////////////////////////////////////
		// Photo Popup
		let photoPopup = '';
		if (this.state.photoStatus === 1) {
			photoPopup =
				<div id="photoPopup">
					<div id="photoPopup__content" className="ww-form">
						<div id="photoPopup__media">
							<video autoPlay playsInline muted></video>
							<p id="photoPopup_error">{this.state.photoError}</p>
						</div>
						<p id="photoPopup_message"><em>{escStr(this.props.appData.tStrings.signalement_photo_prendre_aide)}</em></p>
						<div id="photoPopup__buttons">
							<button className="ww-button--primary" type="button" onClick={() => {this.setState({photoStatus: 0})}}>{escStr(this.props.appData.tStrings.annuler)}</button>
							<button className="ww-button--secondary" type="button" onClick={this.takeSnapshot}>{escStr(this.props.appData.tStrings.signalement_photo)}</button>
						</div>
					</div>
				</div>;
		}
		if (this.state.photoStatus === 2) {
			photoPopup =
				<div id="photoPopup">
					<div id="photoPopup__content" className="ww-form">
						<div id="photoPopup__media">
							<img id="photoPopup__image" alt="" />
						</div>
						<p id="photoPopup_message"><em>{escStr(this.props.appData.tStrings.signalement_photo_valider_aide)}</em></p>
						<div id="photoPopup__buttons">
							<button className="ww-button--primary" type="button" onClick={() => {this.setState({photoStatus: 0})}}>{escStr(this.props.appData.tStrings.annuler)}</button>
							<button className="ww-button--secondary" type="button" onClick={this.saveSnapshot}>{escStr(this.props.appData.tStrings.envoyer)}</button>
						</div>
					</div>
				</div>;
		}


		///////////////////////////////////////
		// Page Title
		let title = '';
		for (let i=0;i<this.props.appData.contents.length;i++) {
			if (this.props.appData.contents[i].special === 'signalement') {
				title = this.props.appData.contents[i].title;
				break;
			}
		}

		pageContent =
			<div className="page-content">
				<Navbar
					historyProps={this.props.historyProps}
					handleClickEvent={this.props.handleClickEvent}
					appOptions={this.props.appOptions}
					appData={this.props.appData}
					splashData={this.props.splashData}
				/>
				<h2 className="ww-boxList__title ww-marginBottom--none">{escStr(title)}</h2>
				{introduction}
				{beta}
				{typeSelect}
				{graviteSelect}
				{signalementMap}
				{signalementForm}
				{remerciements}
				{photoPopup}
				<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
					<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
				</div>
			</div>;


		return(
			<div className={pageClass}>
				{pageContent}
			</div>
		);
	}


	///////////////////////////////////////
	// Handle Select Categorie
	handleSelect(listId, value) {
		if (listId === "select-type") { this.setState({ formType : value }); }
		if (listId === "select-gravite") { this.setState({ formGravite : value }); }
	}


	///////////////////////////////////////
	// Handle Change
	handleChange(name, value) {
		if (name === 'adresse') { this.setState({formAdresse: value}); }
		if (name === 'description') { this.setState({formDescription: value}); }
		if (name === 'email') { this.setState({formEmail: value}); }
	}


	///////////////////////////////////////
	// Send Signalement Form
	sendSignalementForm(){

		const notice = document.getElementById('ww-form__message');
		if (notice !== null) { notice.innerHTML = ''; }

		if (this.state.formType === -1 || this.state.formGravite === -1 || this.state.formDescription === '') {
			notice.innerHTML = escStr(this.props.appData.tStrings.signalement_formulaire_incomplet);
			return;
		}
		if (this.state.formAdresse === '' && this.userLocation === null) {
			notice.innerHTML = escStr(this.props.appData.tStrings.signalement_formulaire_incomplet);
			return;
		}

		const formData = new FormData();
		formData.append('apikey', this.props.appOptions.backofficeAPIKey);
		formData.append('version', this.props.appOptions.version);
		formData.append('language', this.props.splashData.code);
		formData.append('order', 'signalement');
		formData.append('type', this.state.formType);
		formData.append('gravite', this.state.formGravite);
		formData.append('adresse', this.state.formAdresse);
		formData.append('description', this.state.formDescription);
		formData.append('email', this.state.formEmail);
		formData.append('image', this.photoUrl);
		if (this.userLocation !== null) {
			formData.append('latitude', this.userLocation.coords.latitude);
			formData.append('longitude', this.userLocation.coords.longitude);
		}
		const fetchParams = {
			method: 'POST',
			body: formData,
		};

		fetchWithTimeout(this.props.appOptions.backofficeSubmitUrl, fetchParams, 10000);

		this.setState({formSent: true});
	}


	///////////////////////////////////////
	// Get Photo Stream
	async getPhotoStream() {
		let mediaControl = document.querySelector('video');
		if (mediaControl !== null && 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
			try {
				this.photoStream = await navigator.mediaDevices.getUserMedia({ video: {facingMode: 'environment', zoom: true, width: 480}, audio: false });
			}
			catch { this.photoStream = null; }
			if (this.photoStream) {
				let mediaControl = document.querySelector('video');
				if ('srcObject' in mediaControl) { mediaControl.srcObject = this.photoStream; }
				else if (navigator.mozGetUserMedia) { mediaControl.mozSrcObject = this.photoStream; }
				else { mediaControl.src = (window.URL || window.webkitURL).createObjectURL(this.photoStream); }
			}
			else {
				this.setState({photoError: 'Photo Error ! Unable to activate camera... Impossible d\'activer l\'appareil photo...'});
				return;
			}
		}
		else {
			this.setState({photoError: 'Photo Error ! Unable to activate camera... Impossible d\'activer l\'appareil photo...'});
			return;
		}
	}


	///////////////////////////////////////
	// Stop Video Stream
	stopPhotoStream() {
		if (this.photoStream !== null) {
			this.photoStream.getTracks().forEach(function(track) {
				if (track.readyState === 'live') { track.stop(); }
			});
			this.photoStream = null;
		}
	}


	///////////////////////////////////////
	// Take Snapshot
	takeSnapshot() {
		if (this.photoStream === null) { return; }

		let mediaControl = document.querySelector('video');
		let canvas = document.createElement('canvas');
		canvas.width = mediaControl.videoWidth;
		canvas.height = mediaControl.videoHeight;
		canvas.getContext('2d').drawImage(mediaControl, 0, 0, mediaControl.videoWidth, mediaControl.videoHeight);
		canvas.toBlob((blob) => {
			this.photoBlob = blob;
			this.setState({photoStatus: 2, photoError: ''});
		});
	}


	///////////////////////////////////////
	// Save Snapshot
	saveSnapshot() {
		if (this.state.photoSending === true) { return; }
		this.setState({photoSending: true});

		fetchWithTimeout(this.props.appOptions.backofficeSignalementImageUploadUrl, {method: 'POST', body: this.photoBlob}, 20000)
			.then(response => response.text())
			.then(result => { this.photoUrl = result; this.setState({photoStatus: 3, photoError: '', photoSending: false}); })
			.catch(error => { this.setState({photoStatus: 0, photoError: '', photoSending: false}); });
	}

}

export default SignalementsScreen;
