// React
import React from 'react';

// Tools / Library
import Loader from 'react-loader-spinner';
import {fetchWithTimeout, escStr} from '../Utils';
import {isEmpty} from 'lodash';
import {BiError} from 'react-icons/bi';
import {IoLocationSharp} from 'react-icons/io5';

// Components
import Dialog from './Dialog';
import Box from './Box';

// Ressources
import logoSplash from '../Images/logo-splash.png';
import logoGreen from '../Images/logo-fond-noir.png';
import splash from '../Images/splash.jpg';

const nl2br = require('react-nl2br');

class SplashScreen extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	screenOptions = {
		loaderColor: '#ffffff',
		loaderSize: 80,
	};
	splashData = [];
	splashDataMD5 = '';
	splashSelectedLanguageIndex = -1;
	splashGeolocation = null;
	splashDataUpdated = false;


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = { step: 'loading' };

		// Bind this to functions
		this.getSplashData = this.getSplashData.bind(this);
		this.handleClickEvent = this.handleClickEvent.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		this.splashData = this.props.splashData;
		this.splashDataMD5 = this.props.splashDataMD5;
		this.getSplashData();
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
	}


	///////////////////////////////////////
	// Component Did Update
	componentDidUpdate() {

		// Get App Data
		if (this.state.step === 'appdata') {
			this.getAppData();
		}

		// Init Geolocation
		if (this.state.step === 'geolocation' && this.splashGeolocation === null) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					location => {
						//console.log('Geolocation', location);
						this.splashGeolocation = location;
						// Save Geolocation to Local Storage
						if (typeof(Storage) !== 'undefined') {
							localStorage.setItem('lastGeolocationLatitude', location.coords.latitude);
							localStorage.setItem('lastGeolocationLongitude', location.coords.longitude);
							localStorage.setItem('lastGeolocationAccuracy', location.coords.accuracy);
							localStorage.setItem('lastGeolocationTs', Date.now());
						}
						this.setState({ step : 'start' });
					},
					positionError => {
						this.setState({ step : 'error_geolocation' });
					},
					{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
				);
			}
			else { this.setState({ step : 'error_geolocation' }); }
		}

	}


	///////////////////////////////////////
	// Render
	render() {
		let splashContent = "";
		let splashDialog = "";


		// Version
		let splashVersion = '';
		//let splashVersion = <div className="ww-splash__version">v{this.props.appOptions.version}.{this.props.appOptions.build}</div>;


		// Logo
		let splashLogo = '';
		if (this.state.step === 'languages') { splashLogo = <div className="ww-splash__logo"><img src={logoSplash} alt="Logo" /></div>; }
		let splashLogoVert = '';
		if (this.state.step === 'start') { splashLogoVert = <div className="ww-splash__logo--vert"><img src={logoGreen} alt="Logo" /></div>; }


		// Splash Image Background
		let splashBackground = <div className="ww-splash__background" style={{backgroundImage : `url(${splash})`}}></div>;
		if (this.state.step === 'start') { splashBackground = <div className="ww-splash__background ww-splash__background--color" style={{backgroundColor : '#ffffff'}}></div>; }


		// Start Button
		let splashStartButton = '';
		if (this.state.step === 'start') {
			splashStartButton =
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.setSplashData(this.props.historyProps, this.splashData, this.splashDataMD5, this.splashSelectedLanguageIndex, this.splashDataUpdated) }>
						{escStr(this.splashData[this.splashSelectedLanguageIndex].demarrer)}
					</div>;
			//let taglineTitle = escStr(this.splashData[this.splashSelectedLanguageIndex].tagline_title);
			let tagline = escStr(this.splashData[this.splashSelectedLanguageIndex].tagline);
			splashContent =
					<div className="ww-splash__tagline">
						<p style={{color : '#000000'}}>{nl2br(escStr(tagline))}</p>
					</div>;
		}


		// Loading Screen
		if (this.state.step === 'loading'
				|| this.state.step === 'geolocation') {
			splashContent =
				<Loader
					className="ww-loader"
					type="Oval"
					color={this.screenOptions.loaderColor}
					height={this.screenOptions.loaderSize}
					width={this.screenOptions.loaderSize}
				/>
		}


		// Language Switcher Screen
		else if (this.state.step === 'languages') {
			splashContent =
				<ul className="ww-splash__languages">
					{this.splashData.map( (lang, index) => {
						if (lang.code === "fr") {
							return(<li className="ww-button--primary" key={lang.code} onClick={ () => this.handleClickEvent('languages', index) }><span>{lang.name}</span></li>);
						}
						else if (lang.code === "en") {
							return(<li className="ww-button--primary" key={lang.code} onClick={ () => this.handleClickEvent('languages', index) }><span>{lang.name}</span></li>);
						}
						else {
							return(<li className="ww-button--primary" key={lang.code} onClick={ () => this.handleClickEvent('languages', index) }><span>{lang.name}</span></li>);
						}
					})}
				</ul>;
		}


		// Error Network
		else if (this.state.step === 'error_network') {
			let dialogContent =
				<div>
					<BiError size="100px" />
					<div className="ww-dialog__section">
						<h1>Erreur !</h1>
						<p>Impossible de se connecter au serveur. Veuillez vérifier votre connexion internet et cliquer sur le bouton "RETRY" ci-dessous.</p>
					</div>
					<div className="ww-dialog__section ww-dialog__section--separator">
						<h1>Error !</h1>
						<p>Unable to connect to server. Please check your internet connection and click on the "RETRY" button below.</p>
					</div>
				</div>;
			splashDialog =
				<Dialog
					dialogId="network"
					dialogClose="Retry"
					dialogContent={dialogContent}
					dialogClickEvent={this.handleClickEvent}
					/>;
		}


		// Error Version
		else if (this.state.step === 'error_version') {
			let dialogContent =
				<div>
					<BiError size="100px" />
					<div className="ww-dialog__section">
						<h1>Erreur !</h1>
						<p>Cette version de l'application n'est plus valide. Merci de supprimer et réinstaller l'application.</p>
					</div>
					<div className="ww-dialog__section ww-dialog__section--separator">
						<h1>Error !</h1>
						<p>This version of the application is not valid anymore. Please delete and reinstall the application.</p>
					</div>
				</div>;
			splashDialog =
				<Dialog
					dialogId="version"
					dialogClose="Close"
					dialogContent={dialogContent}
					dialogClickEvent={this.handleClickEvent}
					/>;
		}


		// Geo location error => display help
		else if (this.state.step === 'error_geolocation') {
			let dialogContent =
				<div>
					<IoLocationSharp size="80px" />
					<h1 className="ww-help__title">{this.splashData[this.splashSelectedLanguageIndex].geolocation}</h1>
					{ this.splashData[this.splashSelectedLanguageIndex].geolocation_help.map( (item, count) => {
						let button = "";
						if (item.button_link.length > 0 &  item.button_text.length > 0) {
							button = <p style={{ marginTop : "24px" }}><a className="ww-button--small" target="_blank" href={item.button_link} rel="noopener noreferrer">{item.button_text}</a></p>;
						}
						let image = "";
						if (item.image !== ""
							&& item.image_width > 0
							&& item.image_height >0) {
							image =
								<Box
									boxClass="ww-box--help"
									boxImage={item.image}
									boxImageWidth={item.image_width}
									boxImageHeight={item.image_height}
									/>;
						}

						let help_title = "";
						if (item.title.length > 0) { help_title = <h2>{escStr(item.title)}</h2>; }

						let help_section_class = (item.separator) ? 'ww-dialog__section ww-dialog__section--separator' : 'ww-dialog__section';

						if (item.image_position === "above") {
							return (
								<div className={help_section_class} key={count}>
									{image}
									{help_title}
									<p>{nl2br(escStr(item.text))}</p>
									{button}
								</div>
							);
						}
						else if (item.image_position === "middle") {
							return (
								<div className={help_section_class} key={count}>
									<h2>{escStr(item.title)}</h2>
									{image}
									<p>{nl2br(escStr(item.text))}</p>
									{button}
								</div>
							);
						}
						else {
							return (
								<div className={help_section_class} key={count}>
									<h2>{escStr(item.title)}</h2>
									<p>{nl2br(escStr(item.text))}</p>
									{image}
									{button}
								</div>
							);
						}
					})}
				</div>;

			splashDialog =
				<Dialog
					dialogId="geolocation"
					dialogClose={this.splashData[this.splashSelectedLanguageIndex].fermer}
					dialogContent={dialogContent}
					dialogClickEvent={this.handleClickEvent}
					/>;
		}

		return(
			<div className="ww-splash">
				{splashBackground}
				{splashVersion}
				{splashLogo}
				{splashLogoVert}
				<div className="ww-splash__content">
					{splashContent}
				</div>
				{splashDialog}
				{splashStartButton}
			</div>
		);
	}


	///////////////////////////////////////
	// Get Splash Data
	getSplashData(){

		if (this.state.step !== 'loading') { this.setState({ step: 'loading' }); }
		fetchWithTimeout(this.props.appOptions.backofficeUrl + '?language=fr&version=' + this.props.appOptions.version + '&order=1&apikey=' + this.props.appOptions.backofficeAPIKey, {}, 10000)
			.then(response => response.json())
			.then(result => {
				if (this.mounted === true) {
					//console.log("Splash Data", result);

					// Result OK
					if (result.status === 1) {

						// Update splash data if necessary
						if (typeof(this.props.splashData) !== 'undefined'
							&& (this.splashDataMD5.length === 0 || this.splashDataMD5 !== result.data.splashDataMD5)) {
							this.splashData = result.data.splashData;
							this.splashDataMD5 = result.data.splashDataMD5;
							this.splashDataUpdated = true;
							//console.log("Splash Data Updated");
						}

						if (typeof(this.splashData) !== 'undefined'
							&& this.splashData !== null
							&& !isEmpty(this.splashData)) {
							if (this.splashData.length > 1) {
								this.setState({ step: 'languages' });
							}
							else {
								this.splashSelectedLanguageIndex = 0;
								this.setState({ step : 'geolocation' });
							}
						}
						else {
							this.setState({ 'step': 'error_network' });
						}

					}

					// Version Error
					else if (result.status === 0 && (result.error === 1 || result.error === 2 || result.error === 3 || result.error === 4)) {
						if (typeof(Storage) !== 'undefined') {
							localStorage.removeItem('splashData');
							localStorage.removeItem('splashDataMD5');
						}
						this.setState({ step: 'error_version' });
					}

					// Network Error
					else {
						this.handleSplashDataNetworkError();
					}

				}
			}).catch(error => {
				// Network Error
				this.handleSplashDataNetworkError();
			});

	}


	///////////////////////////////////////
	// Handle Splash Data Network Error
	handleSplashDataNetworkError(){

		if (this.mounted === true) {
			// Network error => try to get splash data from Local Storage
			if (typeof(this.splashData) !== 'undefined'
				&& this.splashData !== null
				&& !isEmpty(this.splashData)) {
				if (this.splashData.length > 1) { this.setState({ step: 'languages' }); }
				else {
					this.splashSelectedLanguageIndex = 0;
					this.setState({ step : 'geolocation' });
				}
			}
			else { this.setState({ 'step': 'error_network' }); }
		}

	}


	///////////////////////////////////////
	// Handle Click Event
	handleClickEvent(context, value){
		switch (context) {

			case 'languages':
				// Set selected language
				this.splashSelectedLanguageIndex = value;
				this.setState({ step : 'geolocation' });
				break;

			case 'dialog':
				// Network Retry
				if (value === 'network') {
					this.getSplashData();
				}
				// Back from Geolocation error
				if (value === 'geolocation') {
					this.setState({ step : 'start' });
				}
				break;

			default:
				break;

		}
	}

}

export default SplashScreen;
