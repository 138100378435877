// React
import React from 'react';

// Tools / Library
import {escStr, processGeolocationDistance} from '../Utils'
import {isEmpty, sortBy} from 'lodash'
import {IoLocationSharp} from 'react-icons/io5';
import {MdKeyboardArrowLeft} from 'react-icons/md';

// Components
import Navbar from './Navbar';
import Box from './Box';

const nl2br = require('react-nl2br');

class EscalesScreen extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	escalesGeolocation = null;


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			geolocation: false,
		};

		// Bind this to functions
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);

		console.log('hello');

		// Init Geolocation
		if (this.state.geolocation === false) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					location => {
						if (this.mounted === true) {
							//console.log('Geolocation', location);
							this.escalesGeolocation = location;
							// Save Geolocation to Local Storage
							if (typeof(Storage) !== 'undefined') {
								localStorage.setItem('lastGeolocationLatitude', location.coords.latitude);
								localStorage.setItem('lastGeolocationLongitude', location.coords.longitude);
								localStorage.setItem('lastGeolocationAccuracy', location.coords.accuracy);
								localStorage.setItem('lastGeolocationTs', Date.now());
							}
							this.setState({ geolocation : true });
						}
					},
					positionError => {
						if (this.mounted === true) {
							this.setState({ geolocation : true });
						}
					},
					{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
				);
			}
			else {
				this.setState({ geolocation : true });
			}
		}

	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Render
	render() {

		///////////////////////////////////////
		// Geolocation Warning
		let geolocation_warning = "";
		if (this.state.geolocation === true && this.escalesGeolocation === null) {
			geolocation_warning =
				<div className="ww-banner">
					{nl2br(escStr(this.props.appData.tStrings.geolocalisation_desactivee))}
				</div>;
		}

		console.log(this.props.appData.escales);


		///////////////////////////////////////
		// List Content
		let listContent = "";
		if (!isEmpty(this.props.appData.escales)) {

			// If Geolocation activated, sort by distance
			let sortedEscales = [];
			for (let i=0; i<this.props.appData.escales.length; i++) {
				let newEscale = this.props.appData.escales[i];
				newEscale.distance_to = 0;
				if (this.state.geolocation === true && this.escalesGeolocation !== null) {
					newEscale.distance_to = processGeolocationDistance(this.escalesGeolocation.coords.latitude, this.escalesGeolocation.coords.longitude, this.props.appData.escales[i].latitude, this.props.appData.escales[i].longitude, false);
				}
				sortedEscales.push(newEscale);
			}
			sortedEscales = sortBy(sortedEscales, [function(o) { return o.distance_to; }]);

			if (!isEmpty(sortedEscales)) {
				listContent =
				<ul className="ww-boxList">
					{sortedEscales.map( escale => {

						// Distance
						let boxPictosDistance = "";
						if (this.state.geolocation === true && this.escalesGeolocation !== null) {
							let userDistance = processGeolocationDistance(this.escalesGeolocation.coords.latitude, this.escalesGeolocation.coords.longitude, escale.latitude, escale.longitude, true);
							boxPictosDistance = <li><IoLocationSharp size="26px" /><span>{userDistance}</span></li>;
						}

						// Pictos list
						let boxPictos =
							<ul className="ww-box__overlayPictos">
								{boxPictosDistance}
							</ul>;

						// Link
						let boxLink = "escale/" + escale.id;

						// Image
						let imageClass = '';
						let imageUrl = escale.image;
						let imageWidth = escale.image_width;
						let imageHeight = escale.image_height;
						if (imageUrl.length === 0) {
							imageClass = 'ww-box__nooverlay';
							imageUrl = this.props.appData.default_image.url;
							imageWidth = this.props.appData.default_image.width;
							imageHeight = this.props.appData.default_image.height;
						}

						let boxContent =
							<div className="ww-box__overlay">
								<div className="ww-box__overlayContent">
									<p className="ww-box__title">{escStr(escale.title)}</p>
								</div>
								{boxPictos}
							</div>;

						return (
							<li key={escale.id}>
								<Box
									boxClass={imageClass}
									boxImage={imageUrl}
									boxImageWidth={imageWidth}
									boxImageHeight={imageHeight}
									boxContent={boxContent}
									handleClickEvent={this.props.handleClickEvent}
									handleClickContext='escales_box'
									handleClickValue={boxLink}
									handleClickHistoryProps={this.props.historyProps}
									/>
							</li>);
					}
					)}
				</ul>;
			}
			else {
				listContent = <h3 className="ww-boxList--empty">{escStr(this.props.appData.tStrings.aucun_resultat)}</h3>;
			}
		}
		else {
			listContent = <h3 className="ww-boxList--empty">{escStr(this.props.appData.tStrings.aucun_resultat)}</h3>;
		}


		// Page Title
		let title = '';
		for (let i=0;i<this.props.appData.contents.length;i++) {
			if (this.props.appData.contents[i].special === 'escales') {
				title = this.props.appData.contents[i].title;
				break;
			}
		}


		return(
			<div className="page-dispatch">
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					<h2 className="ww-boxList__title">{escStr(title)}</h2>
					{geolocation_warning}
					{listContent}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>
			</div>
		);
	}

}

export default EscalesScreen;
