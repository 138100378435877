// React
import React from 'react';

// Tools / Library
import Loader from 'react-loader-spinner';
import {fetchWithTimeout, escStr, getFileBlob, blobToArrayBuffer, analytics_get} from '../Utils';
import {isEmpty, indexOf} from 'lodash';
import {BiError} from 'react-icons/bi';
import {MdKeyboardArrowLeft} from 'react-icons/md';

// Components
import Navbar from './Navbar';
import Dialog from './Dialog';
import Box from './Box';


class DispatchScreen extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	screenOptions = {
		loaderColor: "#0037ff",
		loaderSize: 80,
	};
	appData = {};
	appDataMD5 = '';
	dbFileLoaded = 0;


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			step: 'loading',
		};

		// Bind this to functions
		this.getAppData = this.getAppData.bind(this);
		this.sendAnalytics = this.sendAnalytics.bind(this);
		this.handleClickEvent = this.handleClickEvent.bind(this);

	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);

		// Try to get App Data from Local Storage
		const lsAppData = localStorage.getItem('appData');
		const lsAppDataMD5 = localStorage.getItem('appDataMD5');
		if (typeof(lsAppData) !== 'undefined'
			&& lsAppData !== null
			&& typeof(lsAppDataMD5) !== 'undefined'
			&& lsAppDataMD5 !== null
			) {
			this.appData = JSON.parse(lsAppData);
			this.appDataMD5 = lsAppDataMD5;

			// Check is AppData are out of date
			const lsAppDataTs = localStorage.getItem('appDataTs');
			if (typeof(lsAppDataTs) === 'undefined'
				|| lsAppDataTs === null
				|| (Date.now() - lsAppDataTs > 1 * 60 * 60 * 1000)
				){
				this.appDataMD5 = '';
			}
		}
		if (this.appDataMD5 === '') {
			this.sendAnalytics();
			//this.getAppData();
		}
		else { this.setState({ step : 'display' }); }

	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Render
	render() {
		let content = "";
		let back = "";


		// Loading
		if (this.state.step === 'loading') {
			content =
				<Loader
					className="ww-loader"
					type="Oval"
					color={this.screenOptions.loaderColor}
					height={this.screenOptions.loaderSize}
					width={this.screenOptions.loaderSize}
				/>;
		}


		// Error Network
		else if (this.state.step === 'error_network') {
			let dialogContent =
				<div>
					<BiError size="100px" />
					<div className="ww-dialog__section">
						<h1>Erreur !</h1>
						<p>Impossible de se connecter au serveur. Veuillez vérifier votre connexion internet et cliquer sur le bouton "RETRY" ci-dessous.</p>
					</div>
					<div className="ww-dialog__section ww-dialog__section--separator">
						<h1>Error !</h1>
						<p>Unable to connect to server. Please check your internet connection and click on the "RETRY" button below.</p>
					</div>
				</div>;
			content =
				<Dialog
					dialogId="network"
					dialogClose="Retry"
					dialogContent={dialogContent}
					dialogClickEvent={this.handleClickEvent}
					/>;
		}


		// Display Content List
		if (this.state.step === 'display') {

			// Sticky Post
			let stickyPost = '';
			for (let i=0;i<this.appData.contents.length;i++) {
				if (this.appData.contents[i].type === 'post_sticky') {
					const boxLink = "post/" + this.appData.contents[i].id;
					const boxContent =
						<div className="ww-box__overlay">
							<div className="ww-box__overlayContent">
								<p className="ww-box__title">{escStr(this.appData.contents[i].title)}</p>
							</div>
						</div>;

					// Image
					let imageClass = '';
					let imageUrl = this.appData.contents[i].image;
					let imageWidth = this.appData.contents[i].image_width;
					let imageHeight = this.appData.contents[i].image_height;
					if (imageUrl.length === 0) {
						imageClass = 'ww-box__nooverlay';
						imageUrl = this.appData.default_image.url;
						imageWidth = this.appData.default_image.width;
						imageHeight = this.appData.default_image.height;
					}

					stickyPost =
						<li className="ww-stickyPost">
							<h2>{escStr(this.appData.tStrings.a_la_une)}</h2>
							<Box
								boxClass={imageClass}
								boxImage={imageUrl}
								boxImageWidth={imageWidth}
								boxImageHeight={imageHeight}
								boxContent={boxContent}
								handleClickEvent={this.props.handleClickEvent}
								handleClickContext='dispatch_box'
								handleClickValue={boxLink}
								handleClickHistoryProps={this.props.historyProps}
							/>
						</li>;

					break;
				}
			}

			// Content list
			content =
				<ul className="ww-boxList">
					{stickyPost}
					{this.appData.contents.map( item => {
						let boxLink = "";
						let boxContent = "";

						// Only categorie level-1 are displayed
						if (item.type !== 'categorie-level1') { return ''; }

						// Special type
						let context = 'dispatch_box';
						if (item.special === 'partenaires') { context = 'partenaires_list'; }
						if (item.special === 'visites') { context = 'visites_list'; }
						if (item.special === 'escales') { context = 'escales_list'; }
						if (item.special === 'agenda') { context = 'agenda'; }
						if (item.special === 'signalement') { context = 'signalement'; }

						boxLink = "category/" + item.id;
						boxContent =
							<div className="ww-box__overlay">
								<div className="ww-box__overlayContent">
									<p className="ww-box__title">{escStr(item.title)}</p>
								</div>
							</div>;

						// Image
						let imageClass = '';
						let imageUrl = item.image;
						let imageWidth = item.image_width;
						let imageHeight = item.image_height;
						if (imageUrl.length === 0) {
							imageClass = 'ww-box__nooverlay';
							imageUrl = this.appData.default_image.url;
							imageWidth = this.appData.default_image.width;
							imageHeight = this.appData.default_image.height;
						}

						return(
							<li key={item.id}>
								<Box
									boxClass={imageClass}
									boxImage={imageUrl}
									boxImageWidth={imageWidth}
									boxImageHeight={imageHeight}
									boxContent={boxContent}
									handleClickEvent={this.props.handleClickEvent}
									handleClickContext={context}
									handleClickValue={boxLink}
									handleClickHistoryProps={this.props.historyProps}
								/>
							</li>
						);
					})}
				</ul>

			back =
				<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("dispatch_back", null, this.props.historyProps) }>
					<span><MdKeyboardArrowLeft size="26px" />{escStr(this.appData.tStrings.retour)}</span>
				</div>;
		}


		return(
			<div className="page-dispatch">
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.appData}
						splashData={this.props.splashData}
					/>
					<h2 className="ww-boxList__title">{escStr(this.props.splashData.content_title_main)}</h2>
					{content}
					{back}
				</div>
			</div>
		);
	}


	///////////////////////////////////////
	// Get App Data
	getAppData(){

		fetchWithTimeout(this.props.appOptions.backofficeUrl + '?version=' + this.props.appOptions.version + '&order=2&apikey=' + this.props.appOptions.backofficeAPIKey + '&language=' + this.props.splashData.code, {}, 10000)
			.then(response => response.json())
			.then(result => {
				if (this.mounted) {
					//console.log("App Data", result);
					if (result.status === 1) {
						if (result.data.appDataMD5 !== this.props.appDataMD5) {
							//console.log("App Data Updated");
							this.appData = result.data.appData;
							this.appDataMD5 = result.data.appDataMD5;

							// Save App Data to Local Storage
							this.props.setAppData(result.data.appData, result.data.appDataMD5);


							///////////////////////////////////////
							// Try to save images in indexedDB
							var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
							if (indexedDB) {
								// DB Open and Create
								//console.log("Create/Open new DB");
								var requestOpen = indexedDB.open("haropa", 1);
								requestOpen.onerror = function(event){
									//console.log("Create/Open Error");
									this.setState({ step : 'display' });
								};

								// DB Schema
								requestOpen.onupgradeneeded = function(event) {
									//console.log("IndexedDB OnUpgradeNeeded");
									let db = event.target.result;
									let objectStore = db.createObjectStore('haropa', { keyPath: 'id', autoIncrement: true });
									objectStore.createIndex('url', 'url', { unique: true });
									objectStore.createIndex('data', 'data', { unique: false });
									objectStore.createIndex('type', 'type', { unique: false });
								}

								// DB Open Success
								requestOpen.onsuccess = (function(event){
									//console.log("IndexedDB Create/Open Success");
									let openedDB = event.target.result;

									// First we clean the DB
									//console.log("First clean DB");
									let transaction = openedDB.transaction(['haropa'], 'readwrite');
									let requestClean = transaction.objectStore('haropa').clear();
									requestClean.onerror = function(event){
										//console.log("IndexedDB Clear Error");
										this.setState({ step : 'display' });
									};

									requestClean.onsuccess = (function(event){
										//console.log("IndexedDB Clean Success", event);

										// Build images array to store
										var imagesArray = [];

										// Default Image
										if (this.appData.default_image.url.length > 0
											&& this.appData.default_image.width > 0
											&& this.appData.default_image.height > 0) {
											imagesArray.push(this.appData.default_image.url);
										}

										// Store Content Images
										for (let i=0; i<this.appData.contents.length; i++) {
											if (this.appData.contents[i].image.length > 0
												&& this.appData.contents[i].image_width > 0
												&& this.appData.contents[i].image_height > 0) {
												if (indexOf(imagesArray, this.appData.contents[i].image) === -1) {
													imagesArray.push(this.appData.contents[i].image);
												}
											}
										}

										// Store Escales Images
										for (let i=0; i<this.appData.escales.length; i++) {
											if (this.appData.escales[i].image.length > 0
												&& this.appData.escales[i].image_width > 0
												&& this.appData.escales[i].image_height > 0) {
												if (indexOf(imagesArray, this.appData.escales[i].image) === -1) {
													imagesArray.push(this.appData.escales[i].image);
												}
											}
										}

										// Store Partenaires Images
										for (let i=0; i<this.appData.partenaires.length; i++) {
											if (this.appData.partenaires[i].image.length > 0
												&& this.appData.partenaires[i].image_width > 0
												&& this.appData.partenaires[i].image_height > 0) {
												if (indexOf(imagesArray, this.appData.partenaires[i].image) === -1) {
													imagesArray.push(this.appData.partenaires[i].image);
												}
											}
										}

										// Store Visites Images
										for (let i=0; i<this.appData.visites.length; i++) {
											if (this.appData.visites[i].image.length > 0
												&& this.appData.visites[i].image_width > 0
												&& this.appData.visites[i].image_height > 0) {
												if (indexOf(imagesArray, this.appData.visites[i].image) === -1) {
													imagesArray.push(this.appData.visites[i].image);
												}
											}
										}

										// Store Help Images
										for (let i=0; i<this.appData.help.length; i++) {
											if (this.appData.help[i].image.length > 0
												&& this.appData.help[i].image_width > 0
												&& this.appData.help[i].image_height > 0) {
												if (indexOf(imagesArray, this.appData.help[i].image) === -1) {
													imagesArray.push(this.appData.help[i].image);
												}
											}
										}

										// Store Geolocation Images
										for (let i=0; i<this.props.splashData.length; i++) {
											for (let j=0; j<this.props.splashData[i].geolocation_help.length; i++) {
												if (this.props.splashData[i].geolocation_help[j].image.length > 0
													&& this.props.splashData[i].geolocation_help[j].image_width > 0
													&& this.props.splashData[i].geolocation_help[j].image_height > 0) {
													if (indexOf(imagesArray, this.props.splashData[i].geolocation_help[j].image) === -1) {
														imagesArray.push(this.props.splashData[i].geolocation_help[j].image);
													}
												}
											}
										}

										//console.log("Images Array", imagesArray);

										// Retrieve Images from server
										this.dbFileLoaded = 0;
										for (let i=0; i<imagesArray.length; i++) {
											getFileBlob(imagesArray[i], (function(xhr){
												if (xhr.status === 200) {
													var blobType = xhr.response.type;
													// Convert image from blob to ArrayBuffer
													blobToArrayBuffer(xhr.response)
														.then( (result) => {
															// Store Image in IndexedDB
															let newItem = { url: imagesArray[i], data: result, type: blobType };
															let transaction = openedDB.transaction(['haropa'], 'readwrite');
															let addRequest = transaction.objectStore('haropa').add(newItem);
															addRequest.onsuccess = function() {
																//console.log("add request success");
															};
															transaction.oncomplete = function() {
																//console.log("add transaction complete");
															};
															transaction.onerror = function() {
																//console.log("add transaction error");
															};
														})
														.catch( error => {
															//console.log("Blob To Array Buffer Error", error);
														});
												}

												// Increment File Counter
												this.dbFileLoaded++;
												//console.log("Files Loaded:", this.dbFileLoaded);
												if (this.dbFileLoaded === imagesArray.length) {
													//console.log("All files loaded in DB !");
													this.setState({ step : 'display' });
												}
											}).bind(this));
										}

									}).bind(this);
								}).bind(this);
							}

						}
						//this.setState({ step : 'display' });
					}
					else {
						if (isEmpty(this.appData)) { this.setState({ 'step': 'error_network' }); }
						else { this.setState({ step : 'display' }); }
					}
				}

			}).catch(error => {
				if (this.mounted) {
					if (isEmpty(this.appData)) { this.setState({ 'step': 'error_network' }); }
					else { this.setState({ step : 'display' }); }
				}
			});

	}


	///////////////////////////////////////
	// Send Analytics
	sendAnalytics(){

		const analytics = analytics_get();
		if (Array.isArray(analytics) && analytics.length > 0) {

			const formData = new FormData();
			formData.append('apikey', this.props.appOptions.backofficeAPIKey);
			formData.append('version', this.props.appOptions.version);
			formData.append('language', this.props.splashData.code);
			formData.append('order', 'analytics');
			formData.append('data', JSON.stringify(analytics));
			const fetchParams = {
				method: 'POST',
				body: formData,
			};
			fetchWithTimeout(this.props.appOptions.backofficeSubmitUrl, fetchParams, 10000)
				.then(response => response.json())
				.then(result => {
					if (this.mounted) {
						if (result.status === 1) {
							// Successfully sent => Reset Analytics
							localStorage.removeItem('analytics');
						}
						this.getAppData();
					}
				}).catch(error => { if (this.mounted) { this.getAppData(); } });
		}
		else { this.getAppData(); }

	}


	///////////////////////////////////////
	// Handle Click Event
	handleClickEvent(context, value, historyProps){
		switch (context) {

			case "dialog":
				// Network Retry
				if (value === "network") {
					this.setState({ step : 'loading' });
					this.getAppData();
				}
				break;

			default:
				break;

		}
	}

}

export default DispatchScreen;
